import { useState } from 'react';
import TextField from '@mui/material/TextField';
import styled from 'styled-components';

/* Refactor:seperate searchTerm and matchingIdioms Logic
SearchBar:
Get an input from user and return it using onInput (set the input term in Home) */

interface onSearch {
  isLoading?: (params: boolean) => any;
  onInput: (params: string) => any;
  onTextChange: (e: any) => void;
  setSearchInput: (e: any) => void;
  value?: string;
}
function wordCount(text: string) {
  let totalCount = 0;
  const words = text.split(' ');
  words.forEach(function (word) {
    if (word.length > 0) {
      totalCount++;
    }
  });

  return totalCount;
}

export default function SearchBar(props: onSearch) {
  const [, setSearchTerm] = useState<string>('');

  function validateInput(term: string) {
    let isValid = false;
    const numWords = wordCount(term);
    if (term.length === 0) {
      props.isLoading?.(false);
    }
    if (term.length > 2 && numWords > 1) {
      isValid = true;
    }
    return isValid;
  }
  const searchTermHandler = (term: string) => {
    if (!term) {
      props.onInput('');
      props.setSearchInput('');
    }

    props.isLoading?.(true);

    const isValidInput = validateInput(term);
    setSearchTerm(term.toLowerCase());
    if (isValidInput) {
      props.onInput(term);
    }
  };

  return (
    <div style={{ width: '80%' }}>
      <StyledText
        id="standard-basic"
        label=""
        variant="standard"
        InputProps={{ disableUnderline: true }}
        style={{ width: '100%' }}
        placeholder={'Type here your text in Hebrew'}
        value={props.value}
        onChange={(e) => {
          props.setSearchInput(e.target.value);
          props.onTextChange(e);
        }}
        onInput={(event) => {
          searchTermHandler((event.target as any).value);
        }}
      />
    </div>
  );
}

const StyledText = styled(TextField)`
  text-align: 'right';
`;
