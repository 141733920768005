import { Fragment } from 'react';
import './Header.css';
import { NavLink } from 'react-router-dom';
import { Icon } from './AmandaIcon';
import { ReactComponent as SupportIcon } from '../assets/icons/Support_icon.svg';
import { StyledHeader } from './styles/Header.styled';
import styled from '@emotion/styled';

// <Button onClick={() => navigate("/support")}>support</Button>{" "}
export default function Header() {
  // const navigate = useNavigate();
  // @ts-ignore
  return (
    <Fragment>
      <StyledHeader>
        <NavLink to={'/'}>
          <Icon />
        </NavLink>
        <HelpIcon to="/support">
          <SupportIcon width="80px" />
        </HelpIcon>
      </StyledHeader>
    </Fragment>
  );
}

// Temporary we hide the help icon.
const HelpIcon = styled(NavLink)`
  display: none;
  cursor: pointer;
  > svg {
    width: 60px;
    fill: white;
  }
`;
