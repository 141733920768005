import { AmandaHeaderIcon } from "./amandaHeader";
import styled from "@emotion/styled";
export const Icon = () => {
  return (
    <div >
      <AmandaLogo />
    </div>
  );
};


export const  AmandaLogo = styled(AmandaHeaderIcon)`
    svg {
      width: inherit !important;
    }
`;
