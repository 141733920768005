import React, { Fragment } from "react";
import Header from "../Header";

export const Support = () => {
  return (
    <Fragment>
      <Header></Header>
      <p>Support page</p>
    </Fragment>
  );
};
