import styled from "@emotion/styled";
import {Button} from "@mui/material";

export const SubmitButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 50px;
`;


export const SearchButton = styled(Button)`
  background: #EB3C5F;
  color: white;
  width: 212px;
  height: 42px;
  border-radius: 30px;
  &:hover {
    //you want this to be the same as the backgroundColor above
    background: #EB3C5F;
  }
`;