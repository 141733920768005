import { useMemo, useState } from 'react';
import SearchBar from '../../SearchBar';
import Header from '../Header';
import { Flex } from '../styles/Flex.styled';
import { ReactComponent as ArrowIcon } from '../../assets/icons/Arrows_icon.svg';
import { ReactComponent as CopyIcon } from '../../assets/icons/copy.svg';
import { map } from 'lodash-es';
import { copyTextToClipboard, findallMatchingIdioms } from '../../lib/utils';
import styled from '@emotion/styled';
import { Button, Snackbar } from '@mui/material';
import { SearchButton, SubmitButtonWrapper } from './home.style';
import { isHebrew } from '../../utils';

/*<Card body="Sorry. Search is currently only available in Hebrew."></Card> */

export default function Home() {
  const [, setLoading] = useState(false);
  const [input, setInput] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [error, setError] = useState('');
  const [showToast, setShowToast] = useState<boolean>(false);
  const [matching, setMatching] = useState<any>(null);
  const [searchInput, setSearchInput] = useState('');

  const ShowMatchingIdioms = (): any => {
    if (!matching?.length) {
      return <>No results found </>;
    }
    return matching && matching.length ? (
      map(matching, (idiom) => {
        return <div key={idiom[0]}>{idiom[1]}</div>;
      })
    ) : (
      <></>
    );
  };

  const onClear = () => {
    setInput('');
    setSearchInput('');
  };

  const handleClick = () => {
    if (matching) {
      copyTextToClipboard(matching[0][1]);
      setShowToast(true);
    }
  };

  const Results = () => {
    return (
      <ResultWrapper>
        {<ShowMatchingIdioms />}

        {matching?.length ? (
          <CopyButton onClick={handleClick}>
            Copy <CopyIcon />
          </CopyButton>
        ) : (
          <></>
        )}
      </ResultWrapper>
    );
  };

  const searchIdiomsHandler = (term: string) => {
    return findallMatchingIdioms(term);
  };

  // const matching = useMemo(() => {
  //     if(input && !isHebrew(input)) {
  //         setError("Sorry. Search is currently only available in Hebrew.")
  //         return null
  //     } else {
  //         setError("")
  //     }
  //     setLoading(false);
  //     return searchIdiomsHandler(input);
  // }, [input]);

  const onSearchClick = () => {
    if (input && !isHebrew(input)) {
      setError('Sorry. Search is currently only available in Hebrew.');
      return null;
    } else if (searchIdiomsHandler(input)?.length === 0) {
      setError(
        'Unfortunately, we were unable to find a match for your search.'
      );
    } else {
      setError('');
    }
    setLoading(false);
    setMatching(searchIdiomsHandler(input));
  };

  const onTextChange = (e: any) => {
    // TODO: Add debounce
    const value = e.target.value;
    if (value) {
      setDisabled(false);
      setError(value);
      setError('');
    } else {
      setDisabled(true);
    }
  };

  const content = useMemo(() => {
    if (error) {
      return <span>{error}</span>;
    }

    if (matching?.length) {
      return <Results />;
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matching, error]);

  return (
    <>
      <Header></Header>
      <Flex>
        <span>HEB</span>
        <LanguageIconSwitcher />

        <span>ENG</span>
      </Flex>

      <ContentWrapper>
        <ClearButton onClick={onClear}>X</ClearButton>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          open={showToast}
          autoHideDuration={2000}
          onClose={() => setShowToast(false)}
          message="Idiom was copied to clipboard"
        />
        <SearchWrapper>
          <SearchBar
            onTextChange={onTextChange}
            setSearchInput={setSearchInput}
            value={searchInput}
            onInput={setInput}
          />
        </SearchWrapper>
        <SubmitButtonWrapper>
          <SearchButton disabled={disabled} onClick={onSearchClick}>
            SEARCH
          </SearchButton>
        </SubmitButtonWrapper>
        <ContentWrap>{content as any}</ContentWrap>
      </ContentWrapper>
    </>
  );
}

const LanguageIconSwitcher = styled(ArrowIcon)`
  height: 85px;
  transform: rotate(180deg);
  fill: white;
`;

const ContentWrapper = styled.div`
  width: 100%;
  padding-top: 30px;
  display: flex;
  flex-direction: column;
`;

const SearchWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const ResultWrapper = styled.div`
  position: relative;
  min-height: 120px;
  font-weight: 800;
`;

const CopyButton = styled(Button)`
  position: absolute;
  right: 0;
  bottom: 0;
  background: #eb3c5f;
  border-radius: 30px;
  color: white;
  display: flex;
  justify-content: center;
  gap: 5px;
  cursor: pointer;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  align-items: center;
  text-align: center;
  &:hover {
    //you want this to be the same as the backgroundColor above
    background: #eb3c5f;
  }
`;

const ContentWrap = styled.div`
  background: #f2f2f2;
  border-radius: 20px;
  padding: 10px 10px;
  margin: 10px 10px;
  min-height: 120px;
`;

const ClearButton = styled.button`
  background: transparent;
  border-color: transparent;
  font-size: 18px;
  color: black;
  text-decoration: none;
  margin-left: 300px;
`;
