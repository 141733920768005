import styled from "styled-components";

export const Flex = styled.div`
  background-color: #9d2042;
  height: 80%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 0 2px solid white;
  border-radius: 0 0 80px 0;
  padding-bottom: 20px;

  & > span {
    background-color: #9d2042;
    color: white;
    border: 2px solid white;
    border-radius: 24px;
    padding: 3px;
    width: 100px;
    margin: 10px;
    text-align: center;
    line-height: 45px;
    font-size: 20px;
  }
  & > ul {
    flex: 1;
  }
`;
