import styled from "styled-components";

export const StyledHeader = styled.header`
  background-color: #9d2042;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  //padding: 40px 0;
  > svg{
     width: 30%;
    height: auto;
  }
`;
