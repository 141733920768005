import React from "react";

export const AmandaHeaderIcon = () => {
  return (
    <svg
      width="150"
      height="90"
      viewBox="0 0 435 137"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="435" height="137" fill="#9D2042" />
      <path
        d="M207.518 47.0597C205.665 45.621 203.053 44.8993 199.687 44.8993C197.636 44.8993 195.711 45.3255 193.907 46.1829C192.102 47.0403 190.55 48.1156 189.258 49.409C188.068 50.6006 187.159 51.7873 186.525 52.9596C185.805 50.7798 184.682 48.9827 183.144 47.578C181.19 45.7954 178.409 44.9041 174.8 44.9041C173.093 44.9041 171.506 45.1996 170.046 45.7857C168.58 46.3718 167.25 47.1323 166.055 48.0575C164.86 48.9875 163.869 50.0145 163.09 51.1334C162.983 51.2836 162.896 51.4337 162.8 51.5839V49.3024C162.8 48.227 162.447 47.3358 161.74 46.6285C161.034 45.9213 160.168 45.5677 159.143 45.5677C158.069 45.5677 157.189 45.9213 156.507 46.6285C155.825 47.3358 155.481 48.2319 155.481 49.3024V80.3762C155.481 81.4515 155.82 82.3428 156.507 83.05C157.189 83.7572 158.069 84.1109 159.143 84.1109C160.168 84.1109 161.034 83.7572 161.74 83.05C162.447 82.3428 162.8 81.4515 162.8 80.3762V60.952C162.8 59.2421 163.201 57.6775 164.009 56.2631C164.812 54.8487 165.949 53.7249 167.414 52.8917C168.88 52.0634 170.563 51.6469 172.464 51.6469C174.462 51.6469 176.024 52.0634 177.146 52.8917C178.268 53.7249 179.062 54.8487 179.526 56.2631C179.99 57.6824 180.223 59.2421 180.223 60.952V80.3713C180.223 81.4467 180.561 82.338 181.248 83.0452C181.93 83.7524 182.81 84.106 183.884 84.106C184.958 84.106 185.834 83.7524 186.52 83.0452C187.202 82.338 187.546 81.4467 187.546 80.3713V61.0247C187.546 59.3148 187.962 57.7502 188.789 56.3358C189.616 54.9213 190.777 53.783 192.267 52.9256C193.757 52.0731 195.45 51.642 197.355 51.642C199.353 51.642 200.93 52.0683 202.076 52.9256C203.223 53.783 204.026 54.9165 204.49 56.3358C204.954 57.755 205.187 59.3148 205.187 61.0247V80.3713C205.187 81.4467 205.54 82.338 206.246 83.0452C206.952 83.7524 207.818 84.106 208.843 84.106C209.917 84.106 210.793 83.7524 211.48 83.0452C212.162 82.338 212.505 81.4467 212.505 80.3713V60.952C212.505 57.973 212.137 55.2701 211.407 52.853C210.662 50.431 209.371 48.5031 207.518 47.0597Z"
        fill="white"
      />
      <path
        d="M254.282 44.9719C253.257 44.9719 252.391 45.3401 251.685 46.0715C250.979 46.8029 250.626 47.6845 250.626 48.7114V50.1065C250.098 49.501 249.494 48.9149 248.797 48.3481C247.482 47.2728 245.929 46.396 244.149 45.7082C242.369 45.0252 240.429 44.6813 238.33 44.6813C235.06 44.6813 232.095 45.5387 229.439 47.2486C226.779 48.9585 224.67 51.3175 223.108 54.3207C221.545 57.3239 220.767 60.8067 220.767 64.7642C220.767 68.6248 221.545 72.0689 223.108 75.0963C224.67 78.1238 226.789 80.507 229.473 82.2411C232.158 83.9752 235.181 84.8423 238.548 84.8423C240.448 84.8423 242.229 84.5129 243.888 83.8541C245.547 83.1954 246.983 82.367 248.207 81.3644C249.174 80.57 249.977 79.7513 250.621 78.9085V80.3762C250.621 81.4515 250.974 82.3428 251.68 83.05C252.386 83.7573 253.252 84.1109 254.278 84.1109C255.351 84.1109 256.227 83.7573 256.914 83.05C257.596 82.3428 257.939 81.4515 257.939 80.3762V48.7114C257.939 47.6361 257.596 46.7448 256.914 46.0376C256.232 45.3255 255.351 44.9719 254.282 44.9719ZM249.523 71.5409C248.546 73.5705 247.182 75.169 245.426 76.3412C243.67 77.5134 241.643 78.0995 239.35 78.0995C237.106 78.0995 235.118 77.5134 233.386 76.3412C231.655 75.169 230.286 73.5705 229.289 71.5409C228.288 69.5161 227.79 67.254 227.79 64.7594C227.79 62.2212 228.288 59.9493 229.289 57.944C230.291 55.9434 231.655 54.3546 233.386 53.1824C235.118 52.0102 237.106 51.424 239.35 51.424C241.643 51.424 243.67 52.0102 245.426 53.1824C247.182 54.3546 248.546 55.9434 249.523 57.944C250.5 59.9493 250.988 62.2212 250.988 64.7594C250.988 67.254 250.5 69.5113 249.523 71.5409Z"
        fill="white"
      />
      <path
        d="M296.069 46.8417C294.169 45.403 291.484 44.6813 288.021 44.6813C286.313 44.6813 284.702 44.9622 283.188 45.5241C281.674 46.086 280.31 46.8417 279.092 47.7959C277.873 48.7502 276.833 49.7771 275.981 50.8767C275.812 51.0995 275.657 51.3175 275.507 51.5354V49.2976C275.507 48.2222 275.154 47.3309 274.448 46.6237C273.742 45.9165 272.876 45.5629 271.85 45.5629C270.777 45.5629 269.896 45.9165 269.214 46.6237C268.532 47.3309 268.189 48.227 268.189 49.2976V80.3713C268.189 81.4467 268.527 82.338 269.214 83.0452C269.896 83.7524 270.777 84.106 271.85 84.106C272.876 84.106 273.742 83.7524 274.448 83.0452C275.154 82.338 275.507 81.4467 275.507 80.3713V60.8019C275.507 59.0435 275.933 57.4693 276.789 56.0742C277.64 54.684 278.825 53.5602 280.339 52.7028C281.853 51.8503 283.609 51.4192 285.607 51.4192C287.755 51.4192 289.423 51.8455 290.618 52.7028C291.813 53.5602 292.655 54.684 293.143 56.0742C293.632 57.4693 293.873 59.0435 293.873 60.8019V80.3713C293.873 81.4467 294.212 82.338 294.899 83.0452C295.581 83.7524 296.461 84.106 297.535 84.106C298.561 84.106 299.426 83.7524 300.133 83.0452C300.839 82.338 301.192 81.4467 301.192 80.3713V60.7292C301.192 57.7502 300.815 55.0473 300.055 52.6302C299.301 50.2131 297.97 48.2852 296.069 46.8417Z"
        fill="white"
      />
      <path
        d="M342.906 29.8734C341.881 29.8734 341.015 30.227 340.309 30.9342C339.603 31.6414 339.25 32.5376 339.25 33.6081V50.1743C338.703 49.5446 338.074 48.9294 337.349 48.3384C336.033 47.2631 334.48 46.3863 332.7 45.6985C330.92 45.0155 329.005 44.6716 326.954 44.6716C323.684 44.6716 320.719 45.5387 318.063 47.2728C315.403 49.0069 313.294 51.3756 311.732 54.3837C310.169 57.3869 309.391 60.8455 309.391 64.7545C309.391 68.6636 310.169 72.1221 311.732 75.1254C313.294 78.1286 315.413 80.5021 318.097 82.2362C320.782 83.9704 323.805 84.8374 327.172 84.8374C329.073 84.8374 330.853 84.508 332.512 83.8493C334.171 83.1905 335.607 82.3477 336.831 81.3207C337.799 80.507 338.601 79.6641 339.245 78.8067V80.3713C339.245 81.4467 339.598 82.338 340.304 83.0452C341.01 83.7524 341.876 84.106 342.902 84.106C343.975 84.106 344.851 83.7524 345.538 83.0452C346.22 82.338 346.563 81.4467 346.563 80.3713V33.6129C346.563 32.4891 346.22 31.5881 345.538 30.9003C344.856 30.2173 343.975 29.8734 342.906 29.8734ZM334.089 76.3412C332.357 77.5134 330.32 78.0995 327.979 78.0995C325.735 78.0995 323.747 77.5134 322.015 76.3412C320.284 75.169 318.915 73.5802 317.918 71.5796C316.917 69.5791 316.419 67.3073 316.419 64.7642C316.419 62.226 316.917 59.9542 317.918 57.9488C318.92 55.9483 320.284 54.3594 322.015 53.1872C323.747 52.015 325.735 51.4289 327.979 51.4289C330.272 51.4289 332.299 52.015 334.055 53.1872C335.811 54.3594 337.175 55.9483 338.152 57.9488C339.129 59.9542 339.617 62.226 339.617 64.7642C339.617 67.3073 339.129 69.5791 338.152 71.5796C337.17 73.5802 335.815 75.169 334.089 76.3412Z"
        fill="white"
      />
      <path
        d="M390.987 46.0376C390.304 45.3304 389.424 44.9768 388.35 44.9768C387.325 44.9768 386.459 45.3449 385.753 46.0763C385.047 46.8078 384.694 47.6894 384.694 48.7163V50.1113C384.166 49.5058 383.562 48.9197 382.865 48.353C381.549 47.2776 379.997 46.4009 378.217 45.713C376.437 45.03 374.497 44.6861 372.398 44.6861C369.128 44.6861 366.163 45.5435 363.507 47.2534C360.847 48.9633 358.738 51.3223 357.176 54.3255C355.613 57.3288 354.835 60.8116 354.835 64.769C354.835 68.6297 355.613 72.0737 357.176 75.1011C358.738 78.1286 360.857 80.5118 363.541 82.2459C366.226 83.9801 369.249 84.8471 372.615 84.8471C374.516 84.8471 376.296 84.5177 377.956 83.859C379.615 83.2002 381.051 82.3719 382.275 81.3692C383.242 80.5748 384.045 79.7562 384.689 78.9133V80.381C384.689 81.4564 385.042 82.3477 385.748 83.0549C386.454 83.7621 387.32 84.1157 388.346 84.1157C389.419 84.1157 390.295 83.7621 390.982 83.0549C391.664 82.3477 392.007 81.4564 392.007 80.381V48.7114C392.012 47.6361 391.669 46.7448 390.987 46.0376ZM383.596 71.5409C382.618 73.5705 381.254 75.169 379.499 76.3412C377.743 77.5134 375.716 78.0995 373.423 78.0995C371.179 78.0995 369.191 77.5134 367.459 76.3412C365.728 75.169 364.359 73.5705 363.362 71.5409C362.361 69.5161 361.863 67.254 361.863 64.7594C361.863 62.2211 362.361 59.9493 363.362 57.944C364.364 55.9434 365.728 54.3546 367.459 53.1824C369.191 52.0101 371.179 51.424 373.423 51.424C375.716 51.424 377.743 52.0101 379.499 53.1824C381.254 54.3546 382.618 55.9434 383.596 57.944C384.573 59.9493 385.061 62.2211 385.061 64.7594C385.056 67.254 384.568 69.5113 383.596 71.5409Z"
        fill="white"
      />
      <path
        d="M102.25 48.3142C99.8506 46.7157 97.0886 45.9117 93.9591 45.9117C90.8972 45.9117 88.1837 46.7109 85.8184 48.3142C83.4531 49.9127 81.5908 52.0828 80.2268 54.8148C78.8627 57.5516 78.1807 60.6517 78.1807 64.1151C78.1807 67.5156 78.8627 70.6011 80.2268 73.367C81.5908 76.1329 83.4579 78.3175 85.8184 79.916C88.1788 81.5145 90.8972 82.3186 93.9591 82.3186C97.0886 82.3186 99.8506 81.5194 102.25 79.916C104.649 78.3175 106.511 76.1329 107.841 73.367C109.172 70.6011 109.839 67.5156 109.839 64.1151C109.839 60.6469 109.172 57.5468 107.841 54.8148C106.511 52.078 104.649 49.9127 102.25 48.3142Z"
        fill="white"
      />
      <path
        d="M94.7911 14.4939C59.5727 8.86529 35.4794 52.5866 45.1486 82.2847C47.8574 90.1125 53.2652 96.6082 60.2063 101.583C60.632 107.609 59.3356 117.268 48.4668 123.313C48.4668 123.313 72.9519 128.079 80.8604 110.927C102.051 116.279 126.396 111.721 137.338 94.5544C137.338 94.5495 171.506 26.7588 94.7911 14.4939ZM119.329 85.4187C119.329 86.8864 118.865 88.1023 117.931 89.071C116.998 90.0398 115.798 90.5194 114.338 90.5194C112.94 90.5194 111.755 90.035 110.792 89.071C109.825 88.1071 109.346 86.8864 109.346 85.4187V83.423C108.465 84.571 107.367 85.6851 106.052 86.7702C104.388 88.1362 102.424 89.2696 100.16 90.1706C97.8964 91.0716 95.4683 91.5221 92.8708 91.5221C88.2756 91.5221 84.1448 90.3402 80.488 87.9715C76.8263 85.6076 73.929 82.3525 71.8007 78.2206C69.6675 74.0839 68.6034 69.3853 68.6034 64.1151C68.6034 58.7142 69.6675 53.9623 71.8007 49.8643C73.929 45.7615 76.8118 42.5451 80.4396 40.2103C84.0674 37.8756 88.1111 36.7082 92.5757 36.7082C95.4392 36.7082 98.0851 37.1732 100.513 38.1081C102.941 39.043 105.06 40.2443 106.855 41.7071C107.803 42.4821 108.635 43.2814 109.351 44.1V42.206C109.351 40.8061 109.834 39.6049 110.797 38.607C111.759 37.6092 112.944 37.1054 114.342 37.1054C115.808 37.1054 117.003 37.5898 117.936 38.5537C118.87 39.5225 119.334 40.7383 119.334 42.206V85.4187H119.329Z"
        fill="white"
      />
    </svg>
  );
};
